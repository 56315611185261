.events-content {
  width: 1600px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px; }

.events-header {
  text-indent: 0;
  padding-top: 0;
  line-height: 150%; }

.event__1 {
  margin: 0 auto; }

.developers {
  color: #FF7000;
  text-decoration: none; }

.item_page{
    display: flex;
    width: 100%;
    height: 100vh;
}
.item_block{
    margin: auto;
    width: 500px;
    height: auto;
    background-color: rgba(84, 84, 84, 0.8);
}


.menu__item{
    position: relative;
}
.menu__category_Img{
    position: absolute;
    content: '';
    bottom: 20px;
    right: 20px;
    width: 75px;
    height: 75px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

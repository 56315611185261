.header-content {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 84px;
  height: 105px;
  width: 155px;
  font-family: Ampero;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 67px;
  letter-spacing: 0.055em;
  color: #FFFFFF; }


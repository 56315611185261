@import "offer";

.musicians-content {
  width: 1600px;
  display: flex;
  justify-content: space-between;
  margin-top: 99px; }

.slide-description {
  margin-left: 199px; }

.slide-description-text {
  display: none; }
.selected-description {
  display: block; }

.slide-header {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.055em;
  color: #FFFFFF; }

.slide-title {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #FFFFFF; }

.slide-paragraf {
  padding-top: 0;
  text-indent: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.055em;
  color: #FFFFFF; }


.offer__slider-counter {
  display: flex;
  justify-content: space-between;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #F7F7F7;
  width: 263px; }

.counter {
  background: url("Vector 82.svg") no-repeat center;
  width: 121px;
  display: flex;
  justify-content: space-between; }

#current {
  display: inline-block;
  margin: auto 0; }

#total {
  display: inline-block;
  margin: auto 0; }



.offer__slider-prev,.offer__slider-next {
  width: 41px;
  height: 41px;
  border: 1px solid #FF7000; }

.offer__slider-prev {
  background: url("Vector 80.svg") no-repeat center; }

.offer__slider-next {
  background: url("Vector 81.svg") no-repeat center; }
.slides-small-images {
  margin-right: 233px; }

.slides-small-image {
  margin-bottom: 22px; }

.selected-img {
  display: none; }


.history-content {
  display: flex;
  margin-top: 50px; }

.text-content {
  margin-top: 18px;
  margin-left: 200px;
  width: 648px; }


h1 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 17px;
  color: #F7F7F7; }

p {
  padding-top: 36px;
  text-indent: 20px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #F7F7F7; }


.wind-of-change {
  margin-left: 44px;
  margin-top: 54px; }

.button {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  margin-left: 200px;
  width: 206px;
  height: 52px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #F7F7F7;
  border: 1px solid #FF7000;
  box-sizing: border-box;
  border-right: 9px solid #FF7000;
  background: url("Group 150.svg") no-repeat right 22.92px top 23px; }

.button-text {
  margin-top: 17px;
  margin-left: 32px; }





::-webkit-scrollbar {
  background: transparent;
  width: 0; }

::-webkit-scrollbar-thumb {
  background-color: #C2C2C2;
  box-shadow: inset 0 0 5px #AEAEAE; }

::-webkit-scrollbar-thumb:hover {
  background-color: #8A8A8A; }

::-webkit-scrollbar-thumb:active {
  background-color: #727272;
  box-shadow: inset 0 0 5px #595959; }

::-webkit-scrollbar-track:hover {
  background-color: #E6E6E6;
  border: 1px solid #CFCFCF; }

::-webkit-scrollbar-corner {
  background: transparent; }

.fun-club-content {
  margin-top: 50px;
  display: flex;
  justify-content: normal; }


.fun-club-offer__slider-counter {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #F7F7F7 !important; }

.fun-club-offer__slide {
  height: 572px;
  overflow: auto; }

.fun-club-offer__slider {
  //margin-left: 291px
  position: absolute;
  width: 357px;
  display: block;
  border-right: 2px solid #757575;
  padding-right: 59px;
  transform: translateX(-517px);
  transition: 0.5s all; }

.fun-club-offer__slider-counter {
  margin-top: 25px; }

.texts-header {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  color: #FFFFFF; }

.texts-paragraf {
  text-indent: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #F7F7F7; }

.fun-club-headers {
  text-align: center;
  margin-bottom: 61px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 17px;
  color: #FFFFFF; }

.album {
  text-align: center;
  display: block;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  color: #FFFFFF;
  margin-bottom: 28px; }

.fun-club-img {
  margin-left: 43px;
  margin-bottom: 26px; }

.chat {
  margin-left: 357px; }

.chat-header {
  text-align: center; }

.chat-window {
  margin-left: 70px;
  height: 651px;
  width: 951px;
  display: block;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #BFBFBF;
  margin-bottom: 15px;
  overflow: auto;
  word-wrap: break-word; }



.enter-message {
  display: block;
  border: 1px solid #757575;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #BFBFBF;
  width: 265px;
  height: 44px;
  text-align: center;
  padding-top: 12px;
  margin-left: 70px; }

.input-field {
  margin-left: 70px;
  margin-top: 15px;
  width: 458px;
  height: 169px;
  border: 1px solid #757575;
  box-sizing: border-box;
  background-color: transparent;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #BFBFBF; }

.guest-warning {
  margin-top: 15px;
  padding-left: 15px;
  display: block;
  border: 1px solid #757575;
  box-sizing: border-box;
  text-align: initial;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #BFBFBF;
  width: 408px;
  height: 44px;
  margin-left: 70px;
  padding-top: 12px; }

.fun-club-button {
  padding-top: 17px;
  padding-left: 32px;
  margin-left: 70px;
  margin-top: 15px;
  cursor: pointer;
  &:active {
    background: url("../history/Group 150.svg") no-repeat right 20.8px top 22.81px;
    animation: anim 0.3s; } }

.fun-club-button-container {
  display: flex;
  min-width: 400px; }

.smiles {
  margin-left: 33px;
  margin-top: 43.13px; }

.react-input-emoji--button--icon {
  display: none; }

.react-input-emoji--button {
  background-image: url("Vector.svg") !important;
  width: 30.86px !important;
  height: 30.86px !important;
  margin-left: 10px !important;
  margin-right: 10px !important; }

.react-emoji {
  margin-top: 15px;
  margin-left: 60px;
  width: 400px; }

.emoji-text-input {
  min-width: 350px;
  height: 45px;
  border-radius: 25px;
  margin: 15px 70px 25px;
  border: none;
  outline: none;
  padding: 5px 15px;
  box-sizing: border-box; }

.emoji {
  display: flex; }

.emoji-picker {
  display: inline-block;
  width: 450px;
  margin-top: 15px; }

.chat-form-content {
  display: inline-block; }

.texts-invoke {
  transform: translateX(0px); }

.texts-invoke-btn {
  text-align: center;
  padding-top: 5px;
  align-items: center;
  left: 335px;
  bottom: 985px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  color: #e5e5e5;
  width: 80px;
  height: 30px;
  border: #ff7000 1px solid;
  transform: rotate(90deg);
  position: absolute;
  cursor: pointer;
  border-radius: 5px 5px 0 0; }

.app-list-item-label {
  line-height: 150%;
  margin: auto 15px;
  max-width: 500px;
  word-wrap: break-word;
  color: #F7F7F7; }


.chat-list-item {
  display: flex;
  justify-content: space-between;
  width: 951px;
  margin-bottom: 15px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #F7F7F7; }

.chat-avatar img {
  border-radius: 50%;
  display: flex;
  margin: auto; }

.sender-name {
  text-align: center; }

.date {
  width: 250px;
  text-align: right; }

.chat-avatar-text {
  display: flex;
  margin:  0 auto;
  border-radius: 50%;
  background: #46b5e5;
  width: 96px;
  height: 96px;
  background: linear-gradient(45deg,#46b5e5 1%,#1e88e5 64%,#40baf5 97%);
  background-image: -ms-linear-gradient(45deg,#46b5e5 1%,#1e88e5 64%,#40baf5 97%);
  background-image: -moz-linear-gradient(45deg,#46b5e5 1%,#1e88e5 64%,#40baf5 97%);
  background-image: -o-linear-gradient(45deg,#46b5e5 1%,#1e88e5 64%,#40baf5 97%);
  background-image: -webkit-linear-gradient(45deg,#46b5e5 1%,#1e88e5 64%,#40baf5 97%);
  background-image: linear-gradient(45deg,#46b5e5 1%,#1e88e5 64%,#40baf5 97%);
  & span {
    display: block;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 17px;
    color: #e5e5e5; } }



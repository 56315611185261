.player-control {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    cursor: pointer;
    margin: 0 1.5rem;
    font-size: 1.3rem;
    color: #FF7000;
  }
}

.login-container {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgba(0,0,0,.80);
}
.continue{
    width: 192px;
    height: 44px;
    text-align: center;
    color: #e8e8e8;
    margin-left: 100px;
    margin-top: 50px;

}

.login-content {
    border-radius: 2px;
    width: 500px;
    display: inline-block;
    margin-top: 100px;
    vertical-align: middle;
    position: relative;
    padding: 35px;
}

.social-btn {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    color: silver;
}

.social-btn img {
    height: 32px;
    float: left;
    margin-top: 10px;
}

.social-btn.google {
    margin-top: 7px;
}

.social-btn.facebook img {
    height: 24px;
    margin-left: 3px;
}

.social-btn.github img {
    height: 24px;
    margin-left: 3px;
}

.signup-link {
    /*color: rgba(0, 0, 0, 0.65);*/
    color: silver;
    font-size: 14px;
}

.login-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    /*color: rgba(0, 0, 0, 0.65);*/
    color: silver;
}

.login-sml{
    color: silver;
font-size: 1em;
    font-weight: 300;
}

.menu {
    &__item {
        width: 350px;
        min-height: 400px;
        box-shadow: 5px 5px 45px rgba(256,256,256,.1);
        border-radius: 8px;
        padding: 20px;
        margin-right: 40px;
        margin-bottom: 40px;
    }
    &__title {
        margin-bottom: 20px;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 17px;
        color: #F7F7F7;
    }
    &__img {
        width: calc(100% + 40px);
        transform: translateX(-20px);
        height: auto;
        object-fit: cover;
        margin-bottom: 20px;
        background: #F7F7F7;
    }
    &__category, &__price {
        display: inline-block;
        padding-top: 17.81px;
        text-align: center;
        width: 94px;
        height: 51.45px;
        margin-bottom: 10px;
        margin-right: 28px;
        background-color: #FF7000;
        font-family: 'Lato',sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        color: #FFFFFF;
        span {
            font-weight: 700;
        }
        &-wrapper{
            display: flex;
            justify-content: space-between;
        }
    }
    &__btn {
        padding-top: 16.82px;
        padding-left: 29.8px;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        width: 162px;
        height: 51.45px;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #F7F7F7;
        border: 1px solid #FF7000;
        box-sizing: border-box;
        border-right: 9px solid #FF7000;
        background: url("Vector (2).svg") no-repeat right 25.8px top 17.81px;
        &:active {
            background: url("../history/Group 150.svg") no-repeat right 20.8px top 22.81px;
            animation: anim 0.3s;
        }
    }
}
@keyframes anim {
    50%{
        transform: scale(0.98);
        border: #D73A08 1px solid;
        border-right: 9px solid #D73A08;
        background: url("../history/Group 150.svg") no-repeat right 15.8px top 22.81px;

    }
    100%{
        transform: scale(1);
        border: #FF7000 1px solid;
        border-right: 9px solid #D73A08;
        background: url("../history/Group 150.svg") no-repeat right 20.8px top 22.81px;
    }
}


